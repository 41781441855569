import React, { useState } from "react";
import { useParams } from "react-router-dom";
import Search from "../../molecules/filter/Search";
import Category from "../../molecules/filter/Category";
import "../vendor/vendor.scss";
import VendorCard from "../../molecules/cards/VendorCard";

import { marryData } from "../../data/navBar/vendordata";
import Pagination from "../../molecules/pagination";
import Venues from "../../molecules/filter/Venues";
import { venuesData } from "../../data/navBar/VenuesData";
import WeddingCard from "../../molecules/cards/WeddingCard";
import ChoiseCard from "../../molecules/cards/ChoiseCard";
import Choise from "../../organisms/choice/Choise";
import InspHero from "../../organisms/hero/InspHero";

const Inspiration = () => {
  const { name } = useParams();
  const itemsPerPage = 12;

  const [currentPage, setCurrentPage] = useState(0);

  // Calculate the index of the first and last item to display
  const indexOfLastItem = (currentPage + 1) * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems = marryData.slice(indexOfFirstItem, indexOfLastItem);

  // Handle page change
  const handlePageChange = ({ selected }) => {
    setCurrentPage(selected);
  };

  return (
    <>
      <div className="my-5 px-5">
        <h2>{name} </h2>
        <p className="my-3">
          We bring you the most beautiful wedding dresses designed by the best
          fashion designers around the world, take a look at the latest bridal
          dresses collections right here.
        </p>

        <div className="my-4 pb-4 border-top">
          <InspHero />
          <div className="row">
            {currentItems.map((card, index) => (
              <div key={index} className="col-md-3 my-2 px-1 mx-0">
                <ChoiseCard
                  img={card.img}
                  title={card.title}
                  people={card.people}
                  price={card.price}
                />
              </div>
            ))}
          </div>
          <Pagination
            itemsPerPage={itemsPerPage}
            handlePageChange={handlePageChange}
            data={marryData}
          />
        </div>
      </div>
      <Choise />
    </>
  );
};

export default Inspiration;
