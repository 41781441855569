import React, { useState } from "react";
import { useParams } from "react-router-dom";
import Search from "../../molecules/filter/Search";
import Category from "../../molecules/filter/Category";
import "../vendor/vendor.scss";
import VendorCard from "../../molecules/cards/VendorCard";

import { marryData } from "../../data/navBar/vendordata";
import Pagination from "../../molecules/pagination";
import Venues from "../../molecules/filter/Venues";
import { venuesData } from "../../data/navBar/VenuesData";
import WeddingCard from "../../molecules/cards/WeddingCard";

const WedingPakages = () => {
  const { name } = useParams();
  const itemsPerPage = 9;
  const [list, setList] = useState({});
  const [currentPage, setCurrentPage] = useState(0);

  // Calculate the index of the first and last item to display
  const indexOfLastItem = (currentPage + 1) * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems = marryData.slice(indexOfFirstItem, indexOfLastItem);

  // Handle page change
  const handlePageChange = ({ selected }) => {
    setCurrentPage(selected);
  };
  const removeOne = (indexToRemove) => {
    setList((prevList) => {
      // Create a copy of the current list
      const newList = { ...prevList };
      // Remove the item at the specified index
      delete newList[indexToRemove];
      // Return the updated list
      return newList;
    });
  };
  const handleInputChange = (e) => {
    if (e.target.value) {
      setList({ ...list, input: e.target.value });
    } else {
      removeOne("input");
    }
  };

  const handleCheckboxChange = (e) => {
    const checkboxValue = e.target.checked;
    const checkboxName = e.target.name;
    if (checkboxValue) {
      setList((prevList) => ({
        ...prevList,
        [checkboxName]: checkboxName,
      }));
    } else {
      removeOne(checkboxName);
    }
  };

  const handleClearFilters = () => {
    setList({});
  };

  return (
    <div className="my-4 px-5">
      <h2>{name} Wedding Packages</h2>
      <div className="row">
        <div className="col-md-6">
          <div className="d-flex">
            <Venues name={name} />
            <Search />
          </div>
        </div>
        <p className="px-2 py-3">
          Looking for that special wedding package in {name}? Here are the
          latest wedding packages from wedding venues in {name}. Visit regularly
          to stay up-to-date with the latest Bahrain wedding packages and share
          the page with other brides and grooms-to-be.
        </p>
      </div>
      <div className="my-4 py-4 border-top">
        <div className="row">
          <div className="col-md-2">
            <input
              type="text"
              className="form-control"
              value={list.input || ""}
              placeholder="Package name"
              onChange={handleInputChange}
            />
            {Object.values(list).length > 0 && (
              <div className="filter">
                <div className="clear_button" onClick={handleClearFilters}>
                  Clear Filters
                </div>
                {Object.entries(list).map(([key, value]) => (
                  <div key={key} className="apply_button my-2" type="button">
                    {value}
                    <i
                      className="fa fa-times"
                      aria-hidden="true"
                      onClick={() => removeOne(key)}
                    />
                  </div>
                ))}
              </div>
            )}
            <fieldset className="mt-3">
              <legend className="font-semibold capitalize">exclusive</legend>
              <ul className="category">
                <li className="my-2">
                  <input
                    id="exclusive-vendors"
                    aria-label="exclusive vendors"
                    type="checkbox"
                    name="exclusive"
                    checked={list.exclusive}
                    className="checkbox"
                    onChange={handleCheckboxChange}
                  />
                  <label htmlFor="exclusive-vendors">Exclusive </label>
                </li>
              </ul>
              <legend className="font-semibold capitalize">Venue Type</legend>
              <ul className="category">
                {venuesData &&
                  venuesData.map((item, index) =>
                    Object.entries(item.submenu).map(([category, subItems]) => (
                      <>
                        {subItems.map((subItem) => (
                          <li key={index} className="my-2">
                            <input
                              id={`exclusive-vendors-${index}`}
                              aria-label={`exclusive vendors ${index}`}
                              type="checkbox"
                              name={subItem.name}
                              checked={list[`exclusive-${index}`]}
                              className="checkbox"
                              onChange={handleCheckboxChange}
                            />
                            <label htmlFor={`exclusive-vendors-${index}`}>
                              {subItem.name}
                            </label>
                          </li>
                        ))}
                      </>
                    ))
                  )}
              </ul>
            </fieldset>
          </div>
          <div className="col-md-10">
            <div className="row">
              {currentItems.map((card, index) => (
                <div key={index} className="col-md-4">
                  <WeddingCard
                    img={card.img}
                    title={card.title}
                    people={card.people}
                    price={card.price}
                  />
                </div>
              ))}
            </div>
            <Pagination
              itemsPerPage={itemsPerPage}
              handlePageChange={handlePageChange}
              data={marryData}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default WedingPakages;
