import React from "react";
import tgt_logo from "../../assets/common/logo.png";
import "../login/login.scss";
import { Link } from "react-router-dom";

const Signup = () => {
  return (
    <div className=" login d-flex flex-column justify-content-center text-center">
      <div className="logo">
        <img
          src={tgt_logo}
          alt="tgt Logo - Uploading"
          className="w-100 h-100"
        />
      </div>
      <h1 class="my-3 text-xl bold capitalize text-gray-900">
      Create A New Account
      </h1>
      <p class="mb-4">
      Already have an account?{" "}
        <Link class="" to="/signin">
          login
        </Link>
      </p>
      <div className="card p-4">
        <form action="">
        <div>
          <label for="Name">
          First and Last Name <span class="text-danger">*</span>
          </label>
          <input
            required
            label="First and Last Name"
            type="text"
            name="Name"
            id="Name"
          />
        </div>
        <div>
          <label for="Email">
          Email <span class="text-danger">*</span>
          </label>
          <input
            required
            label="Email"
            type="email"
            name="email"
            id="Email"
          />
        </div>
        <div>
          <label for="Password">
            Password <span class="text-danger">*</span>
          </label>
          <input
            required
            label="Password"
            type="password"
            name="Password"
            id="Password"
          />
        </div>
        <div>
          <label for="cPassword">
          Confirm Password <span class="text-danger">*</span>
          </label>
          <input
            required
            label="cPassword"
            type="password"
            name="cPassword"
            id="cPassword"
          />
        </div>
        <button className="btn btn-primary my-3 w-100" type="submit">Create Account</button>
        </form>
        
      </div>
    </div>
  );
};

export default Signup;
