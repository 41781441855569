import React from "react";
import "./cards.scss"

const MarryCard = ({ img, title }) => {
  return (
    <div className="d-flex flex-column justify-content-center text-center align-items-center">
      <div className="image_container">
        <img src={img} alt="" />
      </div>
      <p className="marry_text">{title}</p>
    </div>
  );
};

export default MarryCard;
