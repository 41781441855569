export const venuesData = [
  {
    id: 2,
    text: "category",
    submenu: {
      "": [
        {
          id: 1,
          name: "Indoor Restaurant",
          link: "#",
        },
        {
          id: 1,
          name: "Outdoor Restaurant",
          link: "#",
        },
        {
            id: 1,
            name: "Rooftop Weddings",
            link: "#",
          },
          {
            id: 1,
            name: "Poolside Wedding",
            link: "#",
          },
          {
            id: 1,
            name: "Beach Weddings",
            link: "#",
          },
          {
            id: 1,
            name: "Garden Weddings",
            link: "#",
          },
          {
            id: 1,
            name: "Boats And Yachts",
            link: "#",
          },
          {
            id: 1,
            name: "Country Club",
            link: "#",
          },
          {
            id: 1,
            name: "Ballroom Weddings",
            link: "#",
          },
          {
            id: 1,
            name: "Outdoor Weddings",
            link: "#",
          },
          {
            id: 1,
            name: "Indoor Restaurant",
            link: "#",
          },
          {
            id: 1,
            name: "Desert Weddings",
            link: "#",
          },
          {
            id: 1,
            name: "Terrace",
            link: "#",
          },
      ],
    },
  },
];
