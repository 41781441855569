import React, { useState } from "react";
import { useParams } from "react-router-dom";
import DetailSlider from "../../organisms/slider/DetailSlider";
import BookPackages from "./BookPackages";
import PackageDescription from "./PackageDescription";
import AboutPackages from "./AboutPackages";
import MorePackages from "./MorePackages";
import BookNowModal from "./BookNowModal";

const PakagesDetail = () => {
  const [isBookModalShow, setIsBookModalShow] = useState(false);

  const { name } = useParams();
  const data = [
    {
      img: "https://www.arabiaweddings.com/sites/default/files/styles/max980/public/deals/2022/02/i-g2stzgm-x3.jpg",
    },
    {
      img: "https://www.arabiaweddings.com/sites/default/files/styles/max980/public/deals/2022/02/i-bsmg6kj-x2.jpg?itok=9LbIZmvX",
    },
    {
      img: "https://www.arabiaweddings.com/sites/default/files/styles/max980/public/deals/2022/02/d68993bae93cc1a6e55f980ac5efdf37.jpg?itok=rkmJh6O6",
    },
    {
      img: "https://www.arabiaweddings.com/sites/default/files/styles/max980/public/deals/2022/02/cel-03.jpg?itok=XW-57abB",
    },
    {
      img: "https://www.arabiaweddings.com/sites/default/files/styles/max980/public/the_grove_hotel_wedding_setup_-_web_res_-_khalidinho_photography_13_of_13.jpg?itok=J943FOqk",
    },
  ];

  const handleBookModalChange = () => setIsBookModalShow(!isBookModalShow);
  return (
    <>
      {isBookModalShow && <BookNowModal isBookModalShow={true} />}
      <div className="my-4 px-5">
        <h2>{name} </h2>
      </div>

      <div className="border-top border-bottom px-5 d-flex py-3">
        <span>Detail</span>
        <span className="pl-4">Pakages</span>
      </div>
      <div className="my-4 px-5">
        <div className="row">
          <div className="col-md-8 col-sm-12 border-bottom">
            <DetailSlider data={data} />
            <div>
              <i className="fa fa-database" aria-hidden="true"></i> BD 18 pp
            </div>
          </div>
          <div className="col-md-4 d-none d-lg-block">
            <div className="card p-4">
              <BookPackages />
            </div>
          </div>
        </div>
        <div className="d-sm-block d-md-block d-lg-none fixed-bottom card p-2">
          <div className="w-100 m-auto d-flex justify-content-center">
            <div>
              <button
                type="button"
                className="btn text-white bg-book-now"
                onClick={handleBookModalChange}
                data-toggle="modal"
                data-target="#BookNowModalBox"
              >
                Book Now
              </button>
            </div>
          </div>
        </div>

        <div className="row">
          <div className="col-md-8">
            <PackageDescription />
            <AboutPackages />
            <MorePackages />
          </div>
        </div>
      </div>
    </>
  );
};

export default PakagesDetail;
