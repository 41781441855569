import React from "react";
import { Link } from "react-router-dom";
import "./cards.scss"

const ChoiseCard = ({ title, img,isFeaturedBadge }) => {
  return (
    <div class="card mx-2">
      <div className="choise_card_contaiener">
        <img src={img} class="card-img-top" alt="..." />
        {
          isFeaturedBadge && <span className="badge bg-secondary featured-right">FEATURED</span>
        }
       
      </div>
      <div class="card-body card_text">
        <h5 class="card-title">{title}</h5>
       
      </div>
    </div>
  );
};

export default ChoiseCard;
