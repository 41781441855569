import React from 'react'

export default function AboutPackages() {
  return (
    <div>
      Imagine a place that delivers a unique brand of contemporary Arabian hospitality; where multicultural staff are trained to make you feel at home; where people think of you as family, and where it feels like Eid every day… that place is The Grove Hotel. Just a 10-minute drive from Bahrain International Airport in the heart of Amwaj Islands, we are the ideal location to stay for business or pleasure.<br/>

Surrounded by tranquil azure waters, Amwaj Islands is a bustling community of different cultures living in close harmony, and offers a full range of services to its community. The hotel sits a minute away from Amwaj Marina, where our guests have access to the private beach and water sports activities. Also a stone’s throw from The Grove, the popular Amwaj Lagoon offers endless options for dining, shisha and retail therapy.<br/>

The Grove Hotel’s stunning ballroom and conference area spans over 1020 square meters, which can accommodate the largest of wedding parties or corporate events. The versatile space can be transformed into a magical venue for your special day, or a functional space for professional exhibitions and corporate events, easily customized and partitioned to accommodate up to 650 guests.<br/>

Our pre function halls and beautiful bridal reception room are decorated in the same uniquely Grove style, and will not disappoint for your special occasion. Click here for more information on The Grove Hotel Bahrain.
    </div>
  )
}
