import React from 'react'
import BookPackages from './BookPackages'

export default function BookNowModal({isBookModalShow}) {
  return (
    <div>
<div className={`modal fade ${isBookModalShow ? 'show' : ''}`} id="BookNowModalBox" tabIndex="-1" role="dialog" 
aria-labelledby="exampleModalLabel" aria-hidden={!isBookModalShow}
>
  <div class="modal-dialog" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title" id="exampleModalLabel">Modal title</h5>
        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <BookPackages/>
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-secondary" data-dismiss="modal">Close</button>
        <button type="button" class="btn btn-primary">Save changes</button>
      </div>
    </div>
  </div>
</div>
    </div>
  )
}
