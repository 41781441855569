import React from "react";
import MarryCard from "../../molecules/cards/MarryCard";
import { Link } from "react-router-dom";

const Married = () => {
  const marryData = [
    {
      id: 1,
      title: "Tell us what you need",
      img: "https://www.arabiaweddings.com/sites/default/files/uploads/2020/10/01/image1.png",
    },
    {
      id: 1,
      title: "Receive quotes and select a company",
      img: "https://www.arabiaweddings.com/sites/default/files/uploads/2020/10/01/image2.png",
    },
    {
      id: 1,
      title: "Sit back and relax",
      img: "https://www.arabiaweddings.com/sites/default/files/uploads/2020/10/01/image3.png",
    },
  ];
  return (
    <div className="px-lg-5 px-3 my-3">
      <h2 class="my-4 text-center text-xl bold ">
        Getting married?
      </h2>
      <div className="row">
        {marryData.map((card, index) => (
          <div key={index} className="col-md-4">
            <MarryCard img={card.img} title={card.title} />
          </div>
        ))}
    <div className="d-flex flex-column justify-content-center text-center align-items-center w-100 my-3">
    <div className="header-btn">
        <Link to="/">
          <button>Start Here!</button>
        </Link>
      </div>
</div>
      </div>
    </div>
  );
};

export default Married;
