import React, { useState } from "react";
import { Link, NavLink } from "react-router-dom";

// scss
import "./sideBar.scss";
// Data
import { navData } from "../../../data/navBar/Navbar";

const SideBar = ({ mobileMenu, setMobileMenu }) => {
  const [subMenu, setSubMenu] = useState(null);
  const [index, setIndex] = useState("");
  console.log('index',index)

  return (
    <>
      <div
        className="side-bar d-block d-lg-none"
        style={{
          transform:
            mobileMenu === true ? "translateX(-101%)" : "translateX(0%)",
        }}
      >
        <div className="side-bar-close d-flex justify-content-end border-bottom pb-2 mb-2">
          <i
            class="fa fa-times"
            aria-hidden="true"
            onClick={() => setMobileMenu(!mobileMenu)}
          ></i>
        </div>
        <div className="w-100">
          <nav className="desktop-nav-bar">
            <ul className="border-bottom">
              {navData?.map((item, i) => {
                if (i === 4) return null;
                return (
                  <div className="d-flex mb-4 justify-content-between">
                    <li>
                      <div
                        to={item?.link}
                        onClick={() => {
                          setSubMenu(item);
                          setIndex(i);
                        }}
                      >
                        {item?.text}
                      </div>
                    </li>
                    <i class="fa fa-angle-right" aria-hidden="true"></i>
                  </div>
                );
              })}
            </ul>
            <ul className="border-bottom">
              <li className="mb-4">
                <Link to="#">About Us</Link>
              </li>
              <li className="mb-4">
                <Link to="#">Privacy Policy</Link>
              </li>
              <li className="mb-4">
                <Link to="#">Term of Use</Link>
              </li>
              <li className="mb-4">
                <Link to="#">Contact Us</Link>
              </li>
              <li className="mb-4">
                <Link to="#">Are you a Vendors?</Link>
              </li>
            </ul>
            <div className="d-flex justify-content-center text-center align-items-center">
              <ul>
                <li className="mb-4">
                  <Link to="#">Login</Link>
                </li>
                <p>Dont't have an Account?</p>
                <li className="mb-4">
                  <Link to="#">Register Now!</Link>
                </li>
              </ul>
            </div>
          </nav>
        </div>
      </div>
      <div
        className="side-bar d-block d-lg-none index"
        style={{
          transform: subMenu ? "translateX(0%)" : "translateX(-101%)",
        }}
      >
        <div className="side-bar-close d-flex justify-content-between border-bottom pb-2 mb-2">
          <i
            class="fa fa-arrow-left"
            aria-hidden="true"
            onClick={() => setSubMenu(null)}
          ></i>
          <p className="bold">{subMenu?.text}</p>
          <i
            class="fa fa-times"
            aria-hidden="true"
            onClick={() => {
              setMobileMenu(!mobileMenu);
              setSubMenu(null);
            }}
          ></i>
        </div>
        <div className="w-100">
          <nav className="desktop-nav-bar">
            <ul className="border-bottom">
              {subMenu &&
                Object.entries(subMenu.submenu).map(([category, subItems]) => (
                  <li key={category} className="submenu-category mx-3">
                    <p className="my-2 bold">{category}</p>
                    <ul className="submenu-item-mobile border-bottom py-3">
                      {subItems.map((subItem) => (
                        <li key={subItem.id}>
                          <Link
                            to={
                              index == 1
                                ? `/weddingpakages/${subItem.name}`
                                : index == 2
                                ? `/inspiration/${subItem.name}`
                                : `/${subItem.name}`
                            }
                            onClick={() => setIndex("")}
                          >
                            {subItem.name}
                          </Link>
                        </li>
                      ))}
                    </ul>
                  </li>
                ))}
            </ul>
          </nav>
        </div>
      </div>
    </>
  );
};

export default SideBar;
