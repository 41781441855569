export const marryData = [
    {
      id: 1,
      title: "Wedding Package at Waldorf Astoria Ras Al Khaimah",
      img: "https://imagesawe.s3.amazonaws.com/styles/320x250/s3/uploads/images/2024/01/26/016_areal.jpg",
      price: 370,
      people: "",
    },
    {
      id: 1,
      title: "Wedding Package at Jaddaf Rotana Suite Hotel Dubai",
      img: "https://imagesawe.s3.amazonaws.com/styles/320x250/s3/uploads/images/2023/06/07/private_events_1_0.jpg",
      price: 140,
      people: 200,
    },
    {
      id: 1,
      title: "Diamond Wedding Package at Hilton Dubai Palm Jumeirah",
      img: "https://imagesawe.s3.amazonaws.com/styles/320x250/s3/uploads/images/2023/03/17/hilton_dubai_pj_7.png",
      price: 180,
      people: "",
    },
    {
      id: 1,
      title: "Diamond Wedding Package at Hilton Dubai Palm Jumeirah",
      img: "https://imagesawe.s3.amazonaws.com/styles/320x250/s3/uploads/images/2023/03/17/hilton_dubai_pj_1.png",
      price: 340,
      people: "",
    },
    {
      id: 1,
      title: "Wedding Package at Waldorf Astoria Ras Al Khaimah",
      img: "https://imagesawe.s3.amazonaws.com/styles/320x250/s3/uploads/images/2024/01/26/016_areal.jpg",
      price: 370,
      people: "",
    },
    {
      id: 1,
      title: "Wedding Package at Jaddaf Rotana Suite Hotel Dubai",
      img: "https://imagesawe.s3.amazonaws.com/styles/320x250/s3/uploads/images/2023/06/07/private_events_1_0.jpg",
      price: 140,
      people: 200,
    },
    {
      id: 1,
      title: "Diamond Wedding Package at Hilton Dubai Palm Jumeirah",
      img: "https://imagesawe.s3.amazonaws.com/styles/320x250/s3/uploads/images/2023/03/17/hilton_dubai_pj_7.png",
      price: 180,
      people: "",
    },
    {
      id: 1,
      title: "Diamond Wedding Package at Hilton Dubai Palm Jumeirah",
      img: "https://imagesawe.s3.amazonaws.com/styles/320x250/s3/uploads/images/2023/03/17/hilton_dubai_pj_1.png",
      price: 340,
      people: "",
    },
    {
      id: 1,
      title: "Wedding Package at Waldorf Astoria Ras Al Khaimah",
      img: "https://imagesawe.s3.amazonaws.com/styles/320x250/s3/uploads/images/2024/01/26/016_areal.jpg",
      price: 370,
      people: "",
    },
    {
      id: 1,
      title: "Wedding Package at Jaddaf Rotana Suite Hotel Dubai",
      img: "https://imagesawe.s3.amazonaws.com/styles/320x250/s3/uploads/images/2023/06/07/private_events_1_0.jpg",
      price: 140,
      people: 200,
    },
    {
      id: 1,
      title: "Diamond Wedding Package at Hilton Dubai Palm Jumeirah",
      img: "https://imagesawe.s3.amazonaws.com/styles/320x250/s3/uploads/images/2023/03/17/hilton_dubai_pj_7.png",
      price: 180,
      people: "",
    },
    {
      id: 1,
      title: "Diamond Wedding Package at Hilton Dubai Palm Jumeirah",
      img: "https://imagesawe.s3.amazonaws.com/styles/320x250/s3/uploads/images/2023/03/17/hilton_dubai_pj_1.png",
      price: 340,
      people: "",
    },
  ];