import React from "react";
import { Link, NavLink } from "react-router-dom";

// scss
import "./desktopNavBar.scss";

// Data
import { navData } from "../../../data/navBar/Navbar";

const DesktopNavBar = () => {
  return (
    <nav className="desktop-nav-bar d-flex justify-content-end align-items-center h-100">
      {/* <ul className="d-flex flex-wrap ">
        {navData?.map((item) => (
          <li className="menmm">
            <NavLink to={item?.link}>{item?.text}</NavLink>
          </li>
        ))}
      </ul> */}

      <ul className="d-flex flex-wrap d-flex justify-content-center align-items-center h-100">
        {navData.map((item, i) => {
          return (
            <li
              key={item.id}
              className="nav-item h-100 d-flex justify-content-center align-items-center"
            >
              <NavLink to={item.text}>{item.text}</NavLink>
              {item.submenu && (
                <ul className="submenu">
                  <div className="d-flex  px-5 py-2">
                    {Object.entries(item.submenu).map(
                      ([category, subItems]) => (
                        <li key={category} className="submenu-category mx-3">
                          <p className="mb-2 bold">{category}</p>
                          <ul className="submenu-item">
                            {subItems.map((subItem) => (
                              <li key={subItem.id}>
                                <Link
                                  to={
                                    i === 1
                                      ? `/weddingpakages/${subItem.name}`
                                      : i === 2
                                      ? `/inspiration/${subItem.name}`
                                      : `/${subItem.name}`
                                  }
                                >
                                  {subItem.name}
                                </Link>
                              </li>
                            ))}
                          </ul>
                        </li>
                      )
                    )}
                  </div>
                </ul>
              )}
            </li>
          );
        })}
      </ul>
    </nav>
  );
};

export default DesktopNavBar;
