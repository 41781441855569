import React, { useState } from "react";
import { useParams } from "react-router-dom";
import Search from "../../molecules/filter/Search";
import Category from "../../molecules/filter/Category";
import "./vendor.scss";
import VendorCard from "../../molecules/cards/VendorCard";
import ReactPaginate from "react-paginate";
import { marryData } from "../../data/navBar/vendordata";
import Pagination from "../../molecules/pagination";
import VendorBookNowModal from "../../organisms/modals/VendorBookModal";

const Vendor = () => {
  const { name } = useParams();
  const itemsPerPage = 9;
  const [list, setList] = useState({});
  const [currentPage, setCurrentPage] = useState(0);

  // Calculate the index of the first and last item to display
  const indexOfLastItem = (currentPage + 1) * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems = marryData.slice(indexOfFirstItem, indexOfLastItem);

  // Handle page change
  const handlePageChange = ({ selected }) => {
    setCurrentPage(selected);
  };
  const removeOne = (indexToRemove) => {
    setList((prevList) => {
      // Create a copy of the current list
      const newList = { ...prevList };
      // Remove the item at the specified index
      delete newList[indexToRemove];
      // Return the updated list
      return newList;
    });
  };
  const handleInputChange = (e) => {
    if (e.target.value) {
      setList({ ...list, input: e.target.value });
    } else {
      removeOne("input");
    }
  };

  const handleCheckboxChange = (e) => {
    const checkboxValue = e.target.checked;
    const checkboxName = e.target.name;
    if (checkboxValue) {
      setList((prevList) => ({
        ...prevList,
        [checkboxName]: checkboxName,
      }));
    } else {
      removeOne(checkboxName);
    }
  };

  const handleClearFilters = () => {
    setList({});
  };
  const [isBookModalShow, setIsBookModalShow] = useState(false);
  const handleBookModalChange = () =>{ setIsBookModalShow(!isBookModalShow)
  // alert(isBookModalShow)
  };
  return (
    <div className="my-4 px-5">
      <VendorBookNowModal isBookModalShow={true} handleBookModalChange={handleBookModalChange} />

      <h2>{name}</h2>
      <div className="row">
        <div className="col-md-6">
          <div className="d-flex">
            <Category name={name} />
            <Search />
          </div>
        </div>
      </div>
      <div className="my-4 py-4 border-top">
        <div className="row">
          <div className="col-md-2">
            <input
              type="text"
              className="form-control"
              value={list.input || ""}
              placeholder="search by vendor name"
              onChange={handleInputChange}
            />
            {Object.values(list).length > 0 && (
              <div className="filter">
                <div className="clear_button" onClick={handleClearFilters}>
                  Clear Filters
                </div>
                {Object.entries(list).map(([key, value]) => (
                  <div key={key} className="apply_button my-2" type="button">
                    {value}
                    <i
                      className="fa fa-times"
                      aria-hidden="true"
                      onClick={() => removeOne(key)}
                    />
                  </div>
                ))}
              </div>
            )}
            <fieldset className="mt-3">
              <legend className="font-semibold capitalize">featured</legend>
              <ul className="category">
                <li className="my-2">
                  <input
                    id="featured-vendors"
                    aria-label="featured vendors"
                    type="checkbox"
                    name="featuredVendors"
                    checked={list.featuredVendors}
                    className="checkbox"
                    onChange={handleCheckboxChange}
                  />
                  <label htmlFor="featured-vendors">featured vendors</label>
                </li>
              </ul>
            </fieldset>
          </div>
          <div className="col-md-10">
            <div className="row">
              {currentItems.map((card, index) => (
                <div key={index} className="col-md-4">
                  <VendorCard
                    img={card.img}
                    title={card.title}
                    people={card.people}
                    price={card.price}
                    handleBookModalChange={handleBookModalChange}
                  />
                </div>
              ))}
            </div>
            <Pagination
              itemsPerPage={itemsPerPage}
              handlePageChange={handlePageChange}
              data={marryData}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Vendor;
