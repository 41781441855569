import React from "react";
import { Link } from "react-router-dom";

const DestinationCard = ({ title, img }) => {
  return (
    <Link
      to="/cyprus"
      className="d-flex flex-column justify-content-center text-center align-items-center text-black"
    >
      <div class="destination_image_container ">
        <img
          alt="Cyprus"
          title="Cyprus"
          loading="lazy"
          decoding="async"
          data-nimg="fill"
          src={img}
        />
      </div>
      <div class="my-1 country">{title}</div>
    </Link>
  );
};

export default DestinationCard;
