import React from "react";
import VenorForm from "../venorForm";

export default function VendorBookNowModal({
  isBookModalShow,
  
}) {
  return (
    <div>
      <div
        className={`modal fade ${isBookModalShow ? "show" : ""}`}
        id="BookNowModalBox"
        tabIndex="-1"
        role="dialog"
        aria-labelledby="exampleModalLabel"
        aria-hidden={!isBookModalShow}
      >
        <div class="modal-dialog" role="document" >
          <div class="modal-content">
 
            <div class="modal-header">
              <h5 class="modal-title" id="exampleModalLabel">
              MESSAGE VENDOR
              </h5>
              <button
                type="button"
                class="close"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div class="modal-body">
              <VenorForm />
            </div>
           
           
          </div>
        </div>
      </div>
    </div>
  );
}
