import React from "react";
import ChoiseCard from "../../molecules/cards/ChoiseCard";
import SlickSlider from "../slider/SlickSlider";

const Choise = () => {
  const marryData = [
    {
      id: 1,
      title: "Catalonia: An Exclusive and Indulging Honeymoon Destination",
      img: "https://www.arabiaweddings.com/sites/default/files/styles/400x400/public/articles/2023/12/catalonia.png?itok=zzs4nETR",
    },
    {
      id: 1,
      title: "Arabian Desert Safari Trips for Your Honeymoon",
      img: "https://www.arabiaweddings.com/sites/default/files/styles/400x400/public/articles/2023/12/desert_safari_3.jpg?itok=GmSo6Mfy",
    },
    {
      id: 1,
      title: "Desert Safari Trips for Your Honeymoon ",
      img: "https://www.arabiaweddings.com/sites/default/files/styles/400x400/public/articles/2023/12/desert_safari_trips_0.png?itok=ARVVSHXF",
    },
    {
      id: 1,
      title: "Bless Your Marriage with a Umrah Honeymoon",
      img: "https://www.arabiaweddings.com/sites/default/files/styles/400x400/public/articles/2023/11/umrah_for_a_couple.jpg?itok=vcTCtK1W",
    },
    {
      id: 1,
      title: "Catalonia: An Exclusive and Indulging Honeymoon Destination",
      img: "https://www.arabiaweddings.com/sites/default/files/styles/400x400/public/articles/2023/12/catalonia.png?itok=zzs4nETR",
    },
  ];
  return (
    <div className="px-lg-5 px-3 my-4">
      <h2 class="my-4 text-md-left text-center text-xl bold ">
        Editor's choice
      </h2>
      <SlickSlider data={marryData} />
    </div>
  );
};

export default Choise;
