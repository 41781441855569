import React from "react";
import tgt_logo from "../../assets/common/logo.png";
import "./login.scss";
import { Link } from "react-router-dom";

const Login = () => {
  return (
    <div className=" login d-flex flex-column justify-content-center text-center">
      <div className="logo">
        <img
          src={tgt_logo}
          alt="tgt Logo - Uploading"
          className="w-100 h-100"
        />
      </div>
      <h1 class="my-3 text-xl bold capitalize text-gray-900">
        Login to your account
      </h1>
      <p class="mb-4">
        Don't have an account?{" "}
        <Link class="" to="/signup">
          Sign Up Now!
        </Link>
      </p>
      <div className="card p-4">
        <form action="">
        <div>
          <label for="username">
            Username or Email <span class="text-danger">*</span>
          </label>
          <input
            required
            label="Username or Email"
            type="text"
            name="username"
            id="username"
          />
        </div>
        <div>
          <label for="Password">
            Password <span class="text-danger">*</span>
          </label>
          <input
            required
            label="Password"
            type="password"
            name="Password"
            id="Password"
          />
        </div>
        <button className="btn btn-primary my-3 w-100" type="submit">Login</button>
        </form>
        <Link class="" to="/">
          Forgot your password?
        </Link>
      </div>
    </div>
  );
};

export default Login;
