import React from "react";

// scss
import "./footer.scss";

// svg
import whiteLogo from "../../assets/common/logo.png";
import { Link } from "react-router-dom";

// data
import { navData } from "../../data/navBar/Navbar";

const Footer = () => {
  return (
    <div className="">
      <div className="row footer border-top">
        <div className="col-lg-6 border-right d-flex justify-content-end align-items-center">
          <div className="d-flex flex-wrap justify-content-md-end text-right align-items-center ">

          
          <Link to={"#"} className="border-right px-3 ">
            About Us
          </Link>
          <Link to={"#"} className="border-right px-3 ">
            Privacy Policy
          </Link>
          <Link to={"#"} className="border-right px-3 ">
            Term of Use
          </Link>
          <Link to={"#"} className="border-right px-3 ">
           Contact Us
          </Link>
          <Link to={"#"} className=" px-3 ">
          Are you vendor?
          </Link>
        </div></div>
        <div className="col-lg-6 d-flex  align-items-right ">
          <div className="footer-social-media-facebook d-flex justify-content-center align-items-center">
            <i class="fa-brands fa-facebook-f"></i>
          </div>
          <div className="footer-social-media-instagram d-flex justify-content-center align-items-center">
            <i class="fa-brands fa-instagram"></i>
          </div>
          <div className="footer-social-media-youtube d-flex justify-content-center align-items-center">
            <i class="fa-brands fa-youtube"></i>
          </div>
          <div className="footer-social-media-twitter d-flex justify-content-center align-items-center">
            <i class="fa-brands fa-x-twitter"></i>
          </div>
          <div className="footer-social-media-instagram d-flex justify-content-center align-items-center">
          <i class="fa fa-pinterest-p" aria-hidden="true"></i>
          </div>
          <div className="footer-social-media-facebook d-flex justify-content-center align-items-center">
          <i class="fa fa-linkedin" aria-hidden="true"></i>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Footer;
