import React from "react";

export default function VenorForm() {
  return (
    <div>
   
      <form>
        <div className="form-floating mb-3">
          <input
            type="text"
            className="form-control border-bottom"
            id="floatingInput"
            placeholder="name@example.com"
          />
          <label htmlFor="floatingInput">Message</label>
          <span class="book-now-icon">
            <i class="fa-lg fa-regular fa-message"></i>
          </span>
        </div>
        <div className="form-floating mb-3">
          <input
            type="text"
            className="form-control border-bottom"
            id="floatingInput"
            placeholder="name@example.com"
          />
          <label htmlFor="floatingInput">First Name</label>
          <span class="book-now-icon">
            <i class="fa-lg fa-regular fa-user"></i>
          </span>
        </div>
        <div className="form-floating mb-3">
          <input
            type="text"
            className="form-control border-bottom"
            id="floatingInput"
            placeholder="name@example.com"
          />
          <label htmlFor="floatingInput">Last Name</label>
          <span class="book-now-icon">
            <i class="fa-lg fa-regular fa-user"></i>
          </span>
        </div>
        <div className="form-floating mb-3">
          <input
            type="email"
            className="form-control border-bottom"
            id="floatingInput"
            placeholder="name@example.com"
          />
          <label htmlFor="floatingInput">Email</label>
          <span class="book-now-icon">
            <i class="fa-lg fa-solid fa-envelope"></i>
          </span>
        </div>
        <div className="form-floating mb-3">
          <input
            type="tel"
            className="form-control border-bottom"
            id="floatingInput"
            placeholder="name@example.com"
          />
          <label htmlFor="floatingInput">Mobile Number</label>
          <span class="book-now-icon">
            <i class="fa-lg fa-solid fa-phone"></i>
          </span>
        </div>
        <div className="form-floating mb-3">
          <input
            type="date"
            className="form-control border-bottom"
            id="floatingInput"
            placeholder="name@example.com"
          />
          <label htmlFor="floatingInput">Date</label>
        </div>
        <div className="form-floating mb-3">
          <input
            type="email"
            className="form-control border-bottom"
            id="floatingInput"
            placeholder="name@example.com"
          />
          <label htmlFor="floatingInput">Guest</label>
          <span class="book-now-icon">
            <i class="fa-lg fa-solid fa-users"></i>
          </span>
        </div>
        <div>
          <label
            htmlFor="exampleFormControlInput1"
            className="form-label d-block"
          >
            Preferred contact method
          </label>
          <div>
            <div className="form-check form-check-inline">
              <input
                className="form-check-input"
                type="radio"
                name="inlineRadioOptions"
                id="inlineRadio1"
                value="option1"
              />
              <label className="form-check-label" htmlFor="inlineRadio1">
                Phone
              </label>
            </div>
            <div className="form-check form-check-inline">
              <input
                className="form-check-input"
                type="radio"
                name="inlineRadioOptions"
                id="inlineRadio2"
                value="option2"
              />
              <label className="form-check-label" htmlFor="inlineRadio2">
                Email
              </label>
            </div>
            <div className="form-check form-check-inline">
              <input
                className="form-check-input"
                type="radio"
                name="inlineRadioOptions"
                id="inlineRadio2"
                value="option3"
              />
              <label className="form-check-label" htmlFor="inlineRadio2">
                Whatsapp
              </label>
            </div>
          </div>
        </div>
        <div className=" mt-2">
          <button
            className="btn btn-primary w-100 bg-book-now"
            type="button"
          >
            Send
          </button>
        </div>
       
      </form>
    </div>
  );
}
