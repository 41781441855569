import React, { useState, useEffect } from 'react';

// scss
import './scrollToTopBtn.scss'

const ScrollToTopBtn = () => {
    const [isVisible, setIsVisible] = useState(false);

    useEffect(() => {
        const handleScroll = () => {
            const scrollY = window.scrollY;
            setIsVisible(scrollY > 100);
        };
        window.addEventListener('scroll', handleScroll);

        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);

    const scrollToTop = () => {
        window.scrollTo({
            top: 0,
            behavior: 'smooth',
        });
    };

    return (
        <>
            {isVisible && (
                <div className='scroll-to-top-btn d-flex justify-content-center align-items-center'
                    onClick={scrollToTop} >
                    <i class="fa fa-angle-double-up" />
                </div>
            )}
        </>
    );
};



export default ScrollToTopBtn;
