// App.jsx

import React, { lazy, Suspense, useState } from "react";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "./slider.scss";

const Slider = lazy(() => import("react-slick"));

export default function DetailSlider({ data }) {
  const [activeSlide, setActiveSlide] = useState(0);
  const slickSettings = {
    dots: false,
    infinite: true,
    speed: 1000,
    autoplay: false,
    slidesToShow: 1,
    arrow: true,
    afterChange: (index) => setActiveSlide(index),
  };

  return (
    <div className="App">
      <div>
        <Suspense fallback={<div>Loading...</div>}>
          <Slider {...slickSettings}>
            {data &&
              data.map((card, index) => (
                <div className="detail_slider">
                     <span class="badge bg-secondary featured px-2 py-1">featured</span>
                  <div className="active">
                  {activeSlide + 1} { " "} / { " "} {data.length}
                  </div>
                  <img src={card.img} alt="" />
                </div>
              ))}
          </Slider>
        </Suspense>
      </div>
    </div>
  );
}
