import React, { useEffect, useRef, useState } from "react";
import { Link } from "react-router-dom";
import "../../organisms/hero/Hero.scss";
import { venuesData } from "../../data/navBar/VenuesData";

const Venues = ({name}) => {
 
  const [showAll, setshowAll] = useState(false);
  const [showMain, setshowMain] = useState(false);

  const [text, setText] = useState("");

  const inputRef = useRef();

  const filteredData = venuesData
    .map((item) => {
      if (!text) return true; // Return true if text is empty to show all items

      // Filter the submenu based on the text
      const filteredSubmenu = Object.entries(item.submenu).filter(
        ([category, subItems]) =>
          category.toLowerCase().includes(text.toLowerCase()) ||
          subItems.some((subItem) =>
            subItem.name.toLowerCase().includes(text.toLowerCase())
          )
      );

      // If there are no matches in the submenu, return null to exclude the item
      if (filteredSubmenu.length === 0) return null;

      // Otherwise, return the item with the filtered submenu
      return {
        ...item,
        submenu: Object.fromEntries(filteredSubmenu),
      };
    })
    .filter(Boolean); // Remove any null values from the array

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (inputRef.current && !inputRef.current.contains(event.target)) {
        setshowAll(false);
        setshowMain(false);
      }
    };

    // Add event listener when the component mounts
    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);
  return (
    <div className="w-75 my-4 py-2 hero_search d-flex" ref={inputRef}>
      <span><i class="fa fa-search" aria-hidden="true"></i></span>

      <input
        type="text"
        placeholder="All Venues"
        className="hero_input"
        value={text}
        onChange={(e) => {
          setText(e.target.value);
          setshowAll(false);
          setshowMain(true);
        }}
      />
      <i
        class="fa fa-chevron-down"
        aria-hidden="true"
        onClick={() => setshowAll(!showAll)}
      ></i>
      {text && !showAll && showMain && (
        <div className="all_option">
          
          <>
            {filteredData.map((item) => {
              return Object.entries(item.submenu).map(
                ([category, subItems]) => (
                  <div
                    key={category}
                    className="option-category py-2 mb-2 w-100"
                  >
                    <div key={category} className="option_link ">
                      <Link
                        to={`/${name}/${category.toLowerCase()}`}
                        className="  category_link w-100 py-2 px-2"
                      >
                        {category}
                      </Link>
                    </div>
                    {subItems.map((subItem) => (
                      <div key={subItem.id} className="py-2 px-2 option_link ">
                        <Link
                          className="subcategory_link "
                        to={`/${name}/${subItem.name.toLowerCase()}`}

                       
                        >
                          {subItem.name}
                        </Link>
                      </div>
                    ))}
                  </div>
                )
              );
            })}
          </>
        </div>
      )}
      <div className="options">
        {showAll && (
          <>
            <div  className="option-category  w-100">
              <div className="option_link py-2 px-2 ">
                <div className="  category_link w-100 px-2 text-center">
                All Venues
                </div>
              </div>
            </div>
            <div className="option_choise_category">
              {Object.entries(venuesData[0].submenu).map(
                ([category, subItems]) => (
                  <div
                    key={category}
                    className="option-category-venus  w-100"
                  >
                 
                    {subItems.map((subItem) => (
                      <div key={subItem.id} className="pb-3 px-2 option_link ">
                        <Link
                          className="subcategory_link "
                        to={`/${name}/${subItem.name.toLowerCase()}`}

                        >
                          {subItem.name}
                        </Link>
                      </div>
                    ))}
                  </div>
                )
              )}
            </div>
          </>
        )}
      </div>
    </div>
  );
};

export default Venues;
