export const categoryData = [
   
    {
      id: 2,
      text: "category",
      submenu: {
        'Wedding Venues': [
          {
            id: 1,
            name: "Hotels & Resorts",
            link: "#",
          },
    
        ],
        'Wedding Planners': [
         
         
        ],
        'Fashion Boutiques - Women': [
          {
            id: 1,
            name: "Wedding Dresses",
            link: "#",
          },
        
        ],
        'Flower Shops': [
         
         
        ],
        'Invitation Cards': [
         
         
        ],
       
       
      },
    },
  ];
  