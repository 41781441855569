export const SearchData = [
  {
    id: 2,
    text: "Arab",
    submenu: {
      Bahrain: [
        {
          id: 1,
          name: "Manama",
          link: "#",
        },
      ],
      Egypt: [
        {
          id: 1,
          name: "Alexandria",
          link: "#",
        },
        {
          id: 1,
          name: "Cairo",
          link: "#",
        },
        {
          id: 1,
          name: "Ain Sokhna",
          link: "#",
        },
        {
          id: 1,
          name: "Asawan",
          link: "#",
        },
        {
          id: 1,
          name: "Heliopolis",
          link: "#",
        },
        {
          id: 1,
          name: "Luxor",
          link: "#",
        },
        {
          id: 1,
          name: "North Cost",
          link: "#",
        },
        {
          id: 1,
          name: "Girza",
          link: "#",
        },
        {
          id: 1,
          name: "Marsa Alam",
          link: "#",
        },
        {
          id: 1,
          name: "Sharm El Sheikh",
          link: "#",
        },
        {
          id: 1,
          name: "Taba",
          link: "#",
        },
      ],
      Jordan: [
        {
          id: 1,
          name: "Amman",
          link: "#",
        },
        {
          id: 1,
          name: "Aqaba",
          link: "#",
        },
        {
          id: 1,
          name: "Dead Sea",
          link: "#",
        },
        {
          id: 1,
          name: "Irbid",
          link: "#",
        },
        {
          id: 1,
          name: "Mardaba",
          link: "#",
        },
        {
          id: 1,
          name: "Petra",
          link: "#",
        },

        {
          id: 1,
          name: "Zarqa",
          link: "#",
        },
      ],
      Kuwait: [
        {
          id: 1,
          name: "Kuwait City",
          link: "#",
        },
      ],

      Qatar: [
        {
          id: 1,
          name: "Doha",
          link: "#",
        },
      ],
      Lebnon: [
        {
          id: 1,
          name: "Beirut",
          link: "#",
        },
        {
          id: 1,
          name: "Baabda",
          link: "#",
        },
        {
          id: 1,
          name: "Batroun",
          link: "#",
        },
        {
          id: 1,
          name: "Metn",
          link: "#",
        },
        {
          id: 1,
          name: "Shouf",
          link: "#",
        },
        {
          id: 1,
          name: "Byblos",
          link: "#",
        },
        {
          id: 1,
          name: "Keserwan",
          link: "#",
        },
        {
          id: 1,
          name: "Saida",
          link: "#",
        },
        {
          id: 1,
          name: "Tripoli",
          link: "#",
        },
        {
          id: 1,
          name: "Tyre",
          link: "#",
        },
        {
          id: 1,
          name: "Zahle",
          link: "#",
        },
        {
          id: 1,
          name: "Zgharta",
          link: "#",
        },
      ],

      Oman: [
        {
          id: 1,
          name: "Muscat",
          link: "#",
        },
        {
          id: 1,
          name: "Jebel Akhdar",
          link: "#",
        },
        {
          id: 1,
          name: "Musandam",
          link: "#",
        },
        {
          id: 1,
          name: "Salalah",
          link: "#",
        },
        {
          id: 1,
          name: "Nizwa",
          link: "#",
        },
        {
          id: 1,
          name: "Sohar",
          link: "#",
        },
      ],
      Palestine: [
        {
          id: 1,
          name: "Jerusalem",
          link: "#",
        },
        {
          id: 1,
          name: "Ramallah",
          link: "#",
        },
        {
          id: 1,
          name: "Bethlehem",
          link: "#",
        },
        {
          id: 1,
          name: "Nablus",
          link: "#",
        },
        {
          id: 1,
          name: "Al-Bireh",
          link: "#",
        },
        {
          id: 1,
          name: "Gaza",
          link: "#",
        },
        {
          id: 1,
          name: "Hebron",
          link: "#",
        },
        {
          id: 1,
          name: "Jenin",
          link: "#",
        },
        {
          id: 1,
          name: "Jericho",
          link: "#",
        },
        {
          id: 1,
          name: "Tulkarm",
          link: "#",
        },
      ],
      "Saudi Arabia": [
        {
          id: 1,
          name: "Jeddah",
          link: "#",
        },
        {
          id: 1,
          name: "Riyadh",
          link: "#",
        },
        {
          id: 1,
          name: "Dammam",
          link: "#",
        },

        {
          id: 1,
          name: "Khobar",
          link: "#",
        },
        {
          id: 1,
          name: "Mecca",
          link: "#",
        },
        {
          id: 1,
          name: "Al-Qassim",
          link: "#",
        },
        {
          id: 1,
          name: "Al-Hasa",
          link: "#",
        },
        {
          id: 1,
          name: "Arar",
          link: "#",
        },
        {
          id: 1,
          name: "Dhahran",
          link: "#",
        },
        {
          id: 1,
          name: "Hail",
          link: "#",
        },
        {
          id: 1,
          name: "Jubail",
          link: "#",
        },
        {
          id: 1,
          name: "AlUla",
          link: "#",
        },
        {
          id: 1,
          name: "Jazan",
          link: "#",
        },
        {
          id: 1,
          name: "Medina",
          link: "#",
        },
        {
          id: 1,
          name: "Qatif",
          link: "#",
        },
        {
          id: 1,
          name: "Qurayyat",
          link: "#",
        },
        {
          id: 1,
          name: "Tabuk",
          link: "#",
        },
        {
          id: 1,
          name: "Taif",
          link: "#",
        },
        {
          id: 1,
          name: "Yanbu",
          link: "#",
        },
      ],
      "United Arab Emirates": [
        {
          id: 1,
          name: "Abu Dhabi",
          link: "#",
        },
        {
          id: 1,
          name: "Ajman",
          link: "#",
        },
        {
          id: 1,
          name: "Dubai",
          link: "#",
        },
        {
          id: 1,
          name: "Fujairah",
          link: "#",
        },
        {
          id: 1,
          name: "Ras Al Khaimah",
          link: "#",
        },
        {
          id: 1,
          name: "Sharjah",
          link: "#",
        },
        {
          id: 1,
          name: "Umm Al Quawain",
          link: "#",
        },
        {
          id: 1,
          name: "Al Ain",
          link: "#",
        },
      ],
      Algeria: [
        {
          id: 1,
          name: "Algiers",
          link: "#",
        },
      ],
      Iraq: [
        {
          id: 1,
          name: "Baghdad",
          link: "#",
        },
        {
          id: 1,
          name: "Erbil",
          link: "#",
        },
        {
          id: 1,
          name: "Sulaymaniyah",
          link: "#",
        },
      ],
      Morocco: [
        {
          id: 1,
          name: "Marrakesh",
          link: "#",
        },
        {
          id: 1,
          name: "Casablanca",
          link: "#",
        },
        {
          id: 1,
          name: "Agadir",
          link: "#",
        },
        {
          id: 1,
          name: "Rabat",
          link: "#",
        },
        {
          id: 1,
          name: "Tangier",
          link: "#",
        },
      ],
      Tunisia: [
        {
          id: 1,
          name: "Tunis",
          link: "#",
        },
        {
          id: 1,
          name: "Sfax",
          link: "#",
        },
        {
          id: 1,
          name: "Sousse",
          link: "#",
        },
        {
          id: 1,
          name: "Hammamet",
          link: "#",
        },
      ],
      Syria: [
        {
          id: 1,
          name: "Damascus",
          link: "#",
        },
        {
          id: 1,
          name: "Aleppo",
          link: "#",
        },
        {
          id: 1,
          name: "Latakia",
          link: "#",
        },
      ],
      Yemen: [
        {
          id: 1,
          name: "Sanaa",
          link: "#",
        },
        {
          id: 1,
          name: "Aden",
          link: "#",
        },
        {
          id: 1,
          name: "Taiz",
          link: "#",
        },
        {
          id: 1,
          name: "Ibb",
          link: "#",
        },
      ],
    },
  },
  {
    id: 2,
    text: "InterNational",
    submenu: {
      Cyprus: [
        {
          id: 1,
          name: "Limassol",
          link: "#",
        },
        {
          id: 1,
          name: "Paphos",
          link: "#",
        },
        {
          id: 1,
          name: "Protoras",
          link: "#",
        },
        {
          id: 1,
          name: "Larnaca",
          link: "#",
        },
        {
          id: 1,
          name: "Nicosia",
          link: "#",
        },
      ],
      France: [
        {
          id: 1,
          name: "Paris",
          link: "#",
        },
       
      ],
      Greece: [
        {
          id: 1,
          name: "Athens",
          link: "#",
        },
        {
          id: 1,
          name: "Kefalonia",
          link: "#",
        },
        {
            id: 1,
            name: "Rhodes",
            link: "#",
          },
      ],
      Italy: [
        {
          id: 1,
          name: "Milan",
          link: "#",
        },
        {
          id: 1,
          name: "Florence",
          link: "#",
        },
        {
            id: 1,
            name: "Lake Como",
            link: "#",
          },
          {
            id: 1,
            name: "Lake Maggiore",
            link: "#",
          },
          {
            id: 1,
            name: "Rome",
            link: "#",
          },
          {
            id: 1,
            name: "Salerno",
            link: "#",
          },
          {
            id: 1,
            name: "Sardinia",
            link: "#",
          },
          {
            id: 1,
            name: "Sergno",
            link: "#",
          },
          {
            id: 1,
            name: "Siena",
            link: "#",
          },
      ],
      Portugal: [
        {
          id: 1,
          name: "Lisbon",
          link: "#",
        },
        {
          id: 1,
          name: "Porto",
          link: "#",
        },
      ],
      Spain: [
        {
          id: 1,
          name: "Barcelona",
          link: "#",
        },
        {
          id: 1,
          name: "Seville",
          link: "#",
        },
      ],
      Turkey: [
        {
          id: 1,
          name: "Istanbul",
          link: "#",
        },
        {
          id: 1,
          name: "Bodrum",
          link: "#",
        },
      ],
    },
  },
];
