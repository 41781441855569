import React from "react";
import MarryCard from "../../molecules/cards/MarryCard";
import { Link } from "react-router-dom";
import WeddingCard from "../../molecules/cards/WeddingCard";

const Wedding = () => {
  const marryData = [
    {
      id: 1,
      title: "Wedding Package at Waldorf Astoria Ras Al Khaimah",
      img: "https://imagesawe.s3.amazonaws.com/styles/320x250/s3/uploads/images/2024/01/26/016_areal.jpg",
      price:370,
      people:""

    },
    {
      id: 1,
      title: "Wedding Package at Jaddaf Rotana Suite Hotel Dubai",
      img: "https://imagesawe.s3.amazonaws.com/styles/320x250/s3/uploads/images/2023/06/07/private_events_1_0.jpg",
      price:140,
      people:200

    },
    {
      id: 1,
      title: "Diamond Wedding Package at Hilton Dubai Palm Jumeirah",
      img: "https://imagesawe.s3.amazonaws.com/styles/320x250/s3/uploads/images/2023/03/17/hilton_dubai_pj_7.png",
      price:180,
      people:""

    },
    {
      id: 1,
      title: "Diamond Wedding Package at Hilton Dubai Palm Jumeirah",
      img: "https://imagesawe.s3.amazonaws.com/styles/320x250/s3/uploads/images/2023/03/17/hilton_dubai_pj_1.png",
      price:340,
      people:""

    },
  ];
  return (
    <div className="px-lg-5 px-3 my-3">
      <h2 class="my-4 text-md-left text-center text-xl bold ">
        Wedding Packages
      </h2>
      <p class="mb-3 text-md-left text-center text-xl bold ">
        Browse by location to find wedding professionals in your area and view
        photos of their work
      </p>
      <div className="row">
        {marryData.map((card, index) => (
          <div key={index} className="col-md-3">
            <WeddingCard img={card.img} title={card.title} people={card.people} price={card.price} />
          </div>
        ))}
        <div className="d-flex flex-column justify-content-center text-center align-items-center w-100 my-4">
          <Link to="/">
            <button className="btn btn-outline-success px-4">
             View More Pakages <i class="fa fa-long-arrow-right ml-2" aria-hidden="true"></i>
            </button>
          </Link>
        </div>
      </div>
    </div>
  );
};

export default Wedding;
