import React from "react";
import { Link } from "react-router-dom";

const WeddingCard = ({ title, price, people, img }) => {
  return (
    <div class="card my-2">
      <div className="wedding_card_contaiener">
        <img src={img} class="card-img-top" alt="..." />
        <span class="badge bg-secondary featured px-2 py-1">featured</span>
        <span class="badge bg-secondary exclusive px-2 py-1">exclusive</span>
      </div>
      <div class="card-body">
        <h5 class="card-title">{title}</h5>
        <p class="wedding-text">
          <i class="fa fa-database mr-3" aria-hidden="true"></i>
          <span>AED {price} pp</span>
        </p>
        <p class="wedding-text mt-2">
          {people ? (
            <>
              <i class="fa fa-users mr-3" aria-hidden="true"></i>
              <span>{people}</span>
            </>
          ) : null}
        </p>
        <Link to={`/pakagesDetail/${title}`} class="btn btn-primary w-100 mt-3">
          See Details
        </Link>
      </div>
    </div>
  );
};

export default WeddingCard;
