import React from "react";
import { Link } from "react-router-dom";

const JournyCard = ({title, img}) => {
  return (
    <Link
      to="/"
      className="journey-container"
    >
      <img
        alt="Style icon"
        loading="lazy"
        width="46"
        height="62"
        src={img}
      />
      <div>{title}</div>
    </Link>
  );
};

export default JournyCard;
