import React, { useState } from "react";
import { useParams } from "react-router-dom";
import DetailSlider from "../../organisms/slider/DetailSlider";

import VenorForm from "../../organisms/venorForm";
import VendorBookNowModal from "../../organisms/modals/VendorBookModal";

const VendorDetail = () => {
  const [isBookModalShow, setIsBookModalShow] = useState(false);

  const { name } = useParams();


  const handleBookModalChange = () => setIsBookModalShow(!isBookModalShow);
  return (
    <>
      {<VendorBookNowModal isBookModalShow={true} />}
      <div className="my-4 px-5">
        <h2>{name} </h2>
      </div>

      <div className=" px-5 d-flex py-2">
        <span>Website</span>
        <span className="pl-4">Phone Number</span>
      </div>
      <div className="my-4 px-5">
        <div className="row">
          <div className="col-md-8 col-sm-12 ">
            <div className="detail_slider">
              <span class="badge bg-secondary featured px-2 py-1">
                featured
              </span>

              <img
                src="https://www.arabiaweddings.com/sites/default/files/styles/max980/public/the_grove_hotel_wedding_setup_-_web_res_-_khalidinho_photography_13_of_13.jpg?itok=J943FOqk"
                alt=""
              />
            </div>
          </div>
          <div className="col-md-4 d-none d-lg-block">
            <div className="card p-4">
              <VenorForm />
            </div>
          </div>
          <div className="mt-3">
            <h5>Categories</h5>
            <ul>
              <li>Wedding Photographers</li>
            </ul>
            <h5>Social Media</h5>
            <div className="d-flex mt-3">
            <i class="fa-xl fas fa-globe"></i>
            <i class="fa-xl fab fa-facebook mx-3"></i>
            <i class="fa-xl fab fa-instagram-square"></i>
            </div>
          </div>
         
        </div>
        <div className="d-sm-block d-md-block d-lg-none fixed-bottom card p-2">
          <div className="w-100 m-auto d-flex justify-content-center">
            <div>
              <button
                type="button"
                className="btn text-white bg-book-now"
                onClick={handleBookModalChange}
                data-toggle="modal"
                data-target="#BookNowModalBox"
              >
                Book Now
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default VendorDetail;
