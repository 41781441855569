import React, { useState } from "react";
import { Link } from "react-router-dom";

// scss
import "./header.scss";

// images
import tgt_logo from "../../assets/common/logo.png";

//components
import DesktopNavBar from "../../molecules/navBar/desktopNavBar/DesktopNavBar";

const Header = ({ mobileMenu, setMobileMenu }) => {
  const [isSearchOpen, setIsSearchOpen] = useState(false);

  const handleSearchClick = () => {
    setIsSearchOpen(!isSearchOpen);
  };
  return (
    <div className="header border-bottom ">
      <div className="header-logo">
        <Link to="/">
        <img
          src={tgt_logo}
          alt="tgt Logo - Uploading"
          className="w-100 h-100"
        />
        </Link>
      </div>
      <div className="w-100 d-none d-lg-block h-100 ">
        <div className="w-100  h-100 d-flex justify-content-center align-items-center">
          <DesktopNavBar />
        </div>
      </div>

      <div className="mobile-nav-bar d-flex d-lg-none w-100  justify-content-end ">
        <i
          class="fa-solid fa-bars"
          onClick={() => setMobileMenu(!mobileMenu)}
        ></i>
      </div>
      {/* <div className={`search-bar ${isSearchOpen ? "open" : ""}`}>
        <div className="search-icon" onClick={handleSearchClick}>
          {isSearchOpen ? (
            <i class="fa fa-times" aria-hidden="true"></i> 
          ) : null}
        </div>
        {isSearchOpen && (
          <input type="text" placeholder="Search..." className="search-input" />
        )}
        <div className="search-icon" onClick={handleSearchClick}>
          {isSearchOpen ? null : (
            <span>&#128269;</span> 
          )}
        </div>
      </div> */}

      <div className="header-btn">
        <Link to="/signin">
          <button>Sign in</button>
        </Link>
      </div>
    </div>
  );
};

export default Header;
