import React from "react";
import DestinationCard from "../../molecules/cards/DestinationCard";

const Destination = () => {
  const marryData = [
    {
      id: 1,
      title: "Cyprus",
      img: "https://www.arabiaweddings.com/sites/default/files/styles/320x250/public/companies/images/2023/01/cyprus_wedding.jpg",
    },
    {
      id: 1,
      title: "Greece",
      img: "https://www.arabiaweddings.com/sites/default/files/styles/320x250/public/companies/images/2023/01/weddings_in_greece.jpg",
    },
    {
      id: 1,
      title: "France",
      img: "https://www.arabiaweddings.com/sites/default/files/styles/320x250/public/companies/images/2023/01/france_wedding_1.jpg?itok=YRGewsBL",
    },
    {
      id: 1,
      title: "Italy",
      img: "https://www.arabiaweddings.com/sites/default/files/styles/320x250/public/companies/images/2023/01/weddings_in_italy.jpg?itok=m1EhmaNO",
    },
    {
      id: 1,
      title: "Spain",
      img: "https://www.arabiaweddings.com/sites/default/files/styles/320x250/public/companies/images/2023/01/spain-weddings.jpg?itok=N1pqGnCm",
    },
    {
      id: 1,
      title: "Turkey",
      img: "https://www.arabiaweddings.com/sites/default/files/styles/320x250/public/companies/images/2023/01/turkey_wedding.jpg?itok=KrGnugI1",
    },
    {
      id: 1,
      title: "Portugal",
      img: "https://www.arabiaweddings.com/sites/default/files/styles/320x250/public/companies/images/2023/01/portugal-weddings.jpg?itok=t6XNlHyF",
    },
   
  ];
  return (
    <div className="px-lg-5 px-3 my-4">
      <h2 class="my-4 text-md-left text-center text-xl bold ">
        Plan your Destination Wedding
      </h2>
      <div className="row">
        {marryData.map((card, index) => (
          <div key={index} className="col">
            <DestinationCard img={card.img} title={card.title} />
          </div>
        ))}
      </div>
    </div>
  );
};

export default Destination;
