import React from "react";
import "./Hero.scss";

import Search from "../../molecules/filter/Search";
const Hero = () => {
  


  return (
    <div className="row hero_main border-bottom">
      <div className="col-md-4 order-md-1 order-2 ">
        <div className="hero_filter px-2">
          <h1 class="text-center  font-bold my-3 ">
            Let's find your wedding team
          </h1>
          <p class="my-3  text-start">
            Search for Wedding Venues and Wedding Suppliers
          </p>
          <Search />
        </div>
      </div>
      <div className="col-md-8 order-md-2 order-1">
        <div className="hero_container">
          <img
            alt="homepage hero image"
            className=" w-100  hero_img"
            src="https://www.arabiaweddings.com/sites/all/themes/arabiaweddings/images/bg/hero-bg.jpg"
          />
        </div>
      </div>
    </div>
  );
};

export default Hero;
