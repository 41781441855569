// App.jsx

import React, { lazy, Suspense } from "react";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "./slider.scss";
import ChoiseCard from "../../molecules/cards/ChoiseCard";
// Use React.lazy to dynamically import the Slider component
const Slider = lazy(() => import("react-slick"));


export default function SlickSlider({ data,isFeaturedBadge }) {
  const slickSettings = {
    dots: true,
    infinite: true,
    speed: 1000,
    autoplay: true,
    slidesToShow: 4,
    responsive: [
      {
        breakpoint: 786,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          infinite: true,
          dots: true
        }
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          infinite: true,
          dots: false
        }
      },
    ]
  };

 

  return (
    <div className="App">
      <div >
        <Suspense fallback={<div>Loading...</div>}>
          <Slider {...slickSettings}>
            {data &&
              data.map((card, index) => (
                <ChoiseCard img={card.img} title={card.title} isFeaturedBadge={isFeaturedBadge}/>
              ))}
          </Slider>
        </Suspense>
      </div>
    </div>
  );
}

SlickSlider.defaultProps={
  isFeaturedBadge:false
}
