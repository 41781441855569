import React from 'react'
import SlickSlider from '../../organisms/slider/SlickSlider';

export default function MorePackages() {
    const morePackages = [
        {
          id: 1,
          title: "Catalonia: An Exclusive and Indulging Honeymoon Destination",
          img: "https://www.arabiaweddings.com/sites/default/files/styles/400x400/public/articles/2023/12/catalonia.png?itok=zzs4nETR",
        },
        {
          id: 1,
          title: "Arabian Desert Safari Trips for Your Honeymoon",
          img: "https://www.arabiaweddings.com/sites/default/files/styles/400x400/public/articles/2023/12/desert_safari_3.jpg?itok=GmSo6Mfy",
        },
        {
          id: 1,
          title: "Desert Safari Trips for Your Honeymoon ",
          img: "https://www.arabiaweddings.com/sites/default/files/styles/400x400/public/articles/2023/12/desert_safari_trips_0.png?itok=ARVVSHXF",
        },
        {
          id: 1,
          title: "Bless Your Marriage with a Umrah Honeymoon",
          img: "https://www.arabiaweddings.com/sites/default/files/styles/400x400/public/articles/2023/11/umrah_for_a_couple.jpg?itok=vcTCtK1W",
        },
        {
          id: 1,
          title: "Catalonia: An Exclusive and Indulging Honeymoon Destination",
          img: "https://www.arabiaweddings.com/sites/default/files/styles/400x400/public/articles/2023/12/catalonia.png?itok=zzs4nETR",
        },
      ];

  return (
    <div className='mt-4'>
      <h4 className='font-weight-bold'>More Packages</h4>
      <SlickSlider data={morePackages} isFeaturedBadge={true}/>
    </div>
  )
}
