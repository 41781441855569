import React from "react";
import "./Hero.scss";

import Search from "../../molecules/filter/Search";
import { Link } from "react-router-dom";
const InspHero = () => {
  


  return (
    <div className="row hero_main border-bottom m-0 p-0 mb-3 bg-neutral">
      <div className="col-md-4 order-md-1 order-2 m-0 p-0">
        <div className="hero_filter px-2">
          <em class="text-center ">
          Getting married soon?
          </em>
          <b class="text-center my-1">
          Looking for a Wedding Dress?
          </b>
          <em class="text-center ">
          Let's help you find it.
          </em>
        <Link to="/" className="btn btn-primary my-3">
        Get Offers Today <span><i class="fa fa-check ml-2" aria-hidden="true"></i></span>
        </Link>
        </div>
      </div>
      <div className="col-md-8 order-md-2 order-1 m-0 p-0">
        <div className="hero_container_hc">
          <img
            alt="homepage hero image"
            className=" w-100  hero_img"
            src="https://www.arabiaweddings.com/sites/default/files/styles/max980/public/images/covers/2017/08/wedding_dress.png?itok=8yLxADKI"
          />
        </div>
      </div>
    </div>
  );
};

export default InspHero;
