import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

const ScrollToTop = ({ top = 0 }) => {
    const location = useLocation();

    useEffect(() => {
        const pageHeight = document.documentElement.scrollHeight;
        const scrollPosition = Math.round((pageHeight * top) / 50);
        window.scrollTo({
            top: scrollPosition,
            behavior: 'smooth',
        });
    }, [location]);

    return null;
};

export default ScrollToTop;
