export const navData = [
  {
    id: 0,
    text: "Vendors",
    link: "/vendors",
    submenu: {
      "Arab Countries": [
        {
          id: 1,
          name: "Algeria",
          link: "#",
        },
        {
          id: 2,
          name: "Bahrain",
          link: "#",
        },
        {
          id: 2,
          name: "Egypt",
          link: "#",
        },

        {
          id: 1,
          name: "Iraq",
          link: "#",
        },
        {
          id: 1,
          name: "Jordan",
          link: "#",
        },
        {
          id: 1,
          name: "Kuwait",
          link: "#",
        },

        {
          id: 1,
          name: "Lebanon",
          link: "#",
        },
        {
          id: 1,
          name: "Morocco",
          link: "#",
        },

        {
          id: 1,
          name: "Oman",
          link: "#",
        },
        {
          id: 1,
          name: "Palestine",
          link: "#",
        },

        {
          id: 1,
          name: "Qatar",
          link: "#",
        },
        {
          id: 1,
          name: "Saudi Arabia",
          link: "#",
        },
        {
          id: 1,
          name: "Syria",
          link: "#",
        },
        {
          id: 1,
          name: "Tunisia",
          link: "#",
        },
        {
          id: 1,
          name: "United Arab Emirates",
          link: "#",
        },
        {
          id: 1,
          name: "Yemen",
          link: "#",
        },
      ],
      InterNational: [
        {
          id: 1,
          name: "Cyprus",
          link: "#",
        },
        {
          id: 1,
          name: "France",
          link: "#",
        },
        {
          id: 1,
          name: "Greece",
          link: "#",
        },
        {
          id: 1,
          name: "Italy",
          link: "#",
        },
        {
          id: 1,
          name: "Portugal",
          link: "#",
        },
        {
          id: 1,
          name: "Spain",
          link: "#",
        },
        {
          id: 1,
          name: "Turkey",
          link: "#",
        },
      ],
    },
  },

  {
    id: 2,
    text: "Wedding Packages",
    link: "/wedding/packages",
    submenu: {
      Bahrain: [
        {
          id: 1,
          name: "Manama",
          link: "#",
        },
      ],
      Egypt: [
        {
          id: 1,
          name: "Alexandria",
          link: "#",
        },
        {
          id: 1,
          name: "Hurghada",
          link: "#",
        },
      ],
      Jordan: [
        {
          id: 1,
          name: "Manama",
          link: "#",
        },
      ],
      Qatar: [
        {
          id: 1,
          name: "Doha",
          link: "#",
        },
      ],
      "Saudi Arabia": [
        {
          id: 1,
          name: "Jeddah",
          link: "#",
        },
      ],
      "United Arab Emirates": [
        {
          id: 1,
          name: "Abu Dhabi",
          link: "#",
        },
        {
          id: 1,
          name: "Ajman",
          link: "#",
        },
       
        {
          id: 1,
          name: "Dubai",
          link: "#",
        },
        {
          id: 1,
          name: "Fujairah",
          link: "#",
        },
        {
          id: 1,
          name: "Ras Al Khaimah",
          link: "#",
        },
        {
          id: 1,
          name: "Sharjah",
          link: "#",
        },
      ],
      Cyprus: [
        {
          id: 1,
          name: "Limassol",
          link: "#",
        },
        {
          id: 1,
          name: "Paphos",
          link: "#",
        },
        {
          id: 1,
          name: "Protoras",
          link: "#",
        },
      ],
      Turkey: [
        {
          id: 1,
          name: "Istanbul",
          link: "#",
        },
      ],
    },
  },
  {
    id: 3,
    text: "Inspiration",
    link: "/inspiration",
    submenu: {
      "": [
        {
          id: 1,
          name: "Bridal Fashion",
          link: "#",
        },
        {
          id: 1,
          name: "Wedding Dresses",
          link: "#",
        },

        {
          id: 1,
          name: "Wedding Dress Designers",
          link: "#",
        },
        {
          id: 1,
          name: "Bridal Jewelry",
          link: "#",
        },
        {
          id: 1,
          name: "Engagement & Wedding Rings",
          link: "#",
        },
        {
          id: 1,
          name: "Health & Beauty",
          link: "#",
        },
        {
          id: 1,
          name: "Bridal Makeup",
          link: "#",
        },
        {
          id: 1,
          name: "Bridal Hairstyle",
          link: "#",
        },
        {
          id: 1,
          name: "Wedding Planning",
          link: "#",
        },
        {
          id: 1,
          name: "Wedding Invitations",
          link: "#",
        },
        {
          id: 1,
          name: "Wedding Shows & Events",
          link: "#",
        },
        {
          id: 1,
          name: "Experts' Corner",
          link: "#",
        },
        {
          id: 1,
          name: "Wedding Design And Decor",
          link: "#",
        },
        {
          id: 1,
          name: "Wedding Flowers",
          link: "#",
        },
        {
          id: 1,
          name: "Wedding Cakes",
          link: "#",
        },
        {
          id: 1,
          name: "Wedding Favors",
          link: "#",
        },
        {
          id: 1,
          name: "Wedding Photography",
          link: "#",
        },
        {
          id: 1,
          name: "Wedding Guests Etiquette",
          link: "#",
        },
        {
          id: 1,
          name: "The Groom",
          link: "#",
        },
        {
          id: 1,
          name: "Wedding Gifts And Registries",
          link: "#",
        },
        {
          id: 1,
          name: "Honeymoon",
          link: "#",
        },
        {
          id: 1,
          name: "Destination Weddings",
          link: "#",
        },
        {
          id: 1,
          name: "Wedding News",
          link: "#",
        },
      ],
    },
  },
  // {
  //   id: 4,
  //   text: "Real Weddings",
  //   link: "/real/weddings",
  //   submenu: {
  //     "": [
  //       {
  //         id: 1,
  //         name: "Royal Weddings",
  //         link: "#",
  //       },
  //       {
  //         id: 1,
  //         name: "Celebrity Weddings",
  //         link: "#",
  //       },

  //       {
  //         id: 1,
  //         name: "Real Luxury Weddings",
  //         link: "#",
  //       },
  //       {
  //         id: 1,
  //         name: "Real Weddings",
  //         link: "#",
  //       },
  //       {
  //         id: 1,
  //         name: "Destination Weddings",
  //         link: "#",
  //       },
  //       {
  //         id: 1,
  //         name: "Lebanese Weddings",
  //         link: "#",
  //       },
  //       {
  //         id: 1,
  //         name: "Qatar Weddings",
  //         link: "#",
  //       },
  //       {
  //         id: 1,
  //         name: "UAE Weddings",
  //         link: "#",
  //       },
  //       {
  //         id: 1,
  //         name: "Jordan Weddings",
  //         link: "#",
  //       },
  //       {
  //         id: 1,
  //         name: "Saudi Weddings",
  //         link: "#",
  //       },
  //       {
  //         id: 1,
  //         name: "Egyptian Weddings",
  //         link: "#",
  //       },
  //       {
  //         id: 1,
  //         name: "Bahrain Weddings",
  //         link: "#",
  //       },
  //       {
  //           id: 1,
  //           name: "Indian Weddings",
  //           link: "#",
  //         },
  //     ],
  //   },
  // },
  // {
  //   id: 5,
  //   text: "Songs",
  //   link: "/songs",
  //   submenu: {
  //       "": [
  //         {
  //           id: 1,
  //           name: "All Songs",
  //           link: "#",
  //         },
  //         {
  //           id: 1,
  //           name: "First Dance Songs",
  //           link: "#",
  //         },
  //         {
  //           id: 1,
  //           name: "Wedding Entrance Songs",
  //           link: "#",
  //         },
  //         {
  //           id: 1,
  //           name: "Slideshow Songs",
  //           link: "#",
  //         },
  //         {
  //           id: 1,
  //           name: "Zaffa",
  //           link: "#",
  //         },
        
  //       ]
  //   }
  // },
  {
    id: 6,
    text: "Business Owners",
    link: "/business/owners",
    submenu: {
        "": [
          {
            id: 1,
            name: "Directory Subscription",
            link: "#",
          },
          {
            id: 1,
            name: "Marketing Opportunities",
            link: "#",
          },
          {
            id: 1,
            name: "Business Dashboard",
            link: "#",
          },
        
        
        ]
    }
  },
  {
    id: 7,
    text: "Get Offers!",
    link: "/offers",
  },
];
