import React from "react";
import JournyCard from "../../molecules/cards/JournyCard";

const Journey = () => {
    const marryData = [
        {
            id: 1,
            title: "Style",
            img: "https://www.arabiaweddings.com/_next/static/media/style-off.70867e25.png",
        },
        {
            id: 1,
            title: "Beauty",
            img: "https://www.arabiaweddings.com/_next/static/media/beauty-off.e8cfc79d.png",
        },
        {
            id: 1,
            title: "Planning",
            img: "https://www.arabiaweddings.com/_next/static/media/planning-off.584efd00.png",
        },
        {
            id: 1,
            title: "Honeymoon",
            img: "https://www.arabiaweddings.com/_next/static/media/honey-off.c58dc314.png",
        },
    ];
    return (
        <div className="px-lg-5 px-3 my-4">
            <h2 class="my-3 text-center text-xl bold ">
                Start your wedding planning journey here
            </h2>
            <div className="row">
                {marryData.map((card, index) => (
                    <div key={index} className="col-md-3 col-6 px-2 py-1">
                        <JournyCard img={card.img} title={card.title} />
                    </div>
                ))}
              
            </div>
        </div>
    );
};

export default Journey;
