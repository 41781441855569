import React from 'react'

export default function PackageDescription() {
  return (
    <div>
      <h5>Details</h5>
      <p>The Gold Wedding Package at The Grove Hotel & Conference Centre includes the following</p>
      <span><strong>benefits: </strong></span>
      <div>
        <ul>
<li>Venue hire on the day of the wedding.</li>
<li>Setup of wedding hall including; luxury table linen and chair covers.</li>
<li>Exclusive use of the pre-function area.</li>
<li>Exclusive use of bridal room for the bride and bridal party.</li>
<li>Al Majlis Venue can be used for gentlemen at an additional charge.</li>
<li>A three tier of 4kg wedding cake designed by our Pastry Artist.</li>
<li>Complimentary Self car parking for wedding guests.</li>
<li>Valet car parking at an additional cost.</li>
<li>Ladies service staff and security on the day of wedding at an additional cost.</li>
<li>The wedding night stay in a Luxury Suite inclusive of dinner and breakfast in the suite.Romantic room setup.</li>
<li>Early check-in (subject to availability) and late check-out until 04:00 PM</li>
<li>Special room rate for the wedding guests.</li>
<li>Authentic Arabic coffee & tea, chilled juices and bottled water.</li>
<li>international buffet menu (1 Assorted Cheese Mirror, 6 cold Mezzeh, 2 Hot Mezzeh, 4 salads, 8 main courses & 5 desserts).</li>
        </ul>
      </div>
      <hr />

      <div>
        <span><strong>Extra Items & Services:</strong></span>
      <ul>
	<li>
		Chocolate Fountain 150 BD</li>
	<li>
		Whole Fresh Lamb Oozi 270 BD</li>
	<li>
		Big Shrimps Decoration Plate 300 BD</li>
	<li>
		Live Shawarma 210 BD</li>
	<li>
		3 Choices of Moroccan or Tunisian Cuisines:&nbsp;BD 2.5 per choice</li>
	<li>
		3 Choices of Bahraini cuisine corner:&nbsp;BD 2.5 per choice</li>
	<li>
		3 Choices of Saudi cuisine corner:&nbsp;BD 2.5 per choice</li>
	<li>
		3 Choices of Italian cuisine corner: BD 2.5 per choice</li>
	<li>
		3 Choices of Chinese cuisine corner: BD 2.5 per choice</li>
	<li>
		3 Choices of French cuisine corner: BD 2.5 per choice</li>
	<li>
		3 Choices of Thai cuisine corner: BD 2.5 per choice</li>
	<li>
		Arabic and Oriental Sweet corner:&nbsp;BD 2.5 per choice</li>
        </ul>
      </div>
      <hr />

     <div>
     <span><strong>Terms &amp; Conditions:&nbsp;</strong></span>
        <ul>
        <li>Rate: BD 18 per person</li>
        </ul>
     </div>

     <hr />
    </div>
  )
}
