import React, { useEffect, useRef, useState } from 'react'
import { SearchData } from '../../data/navBar/Search';
import { Link } from 'react-router-dom';
import "../../organisms/hero/Hero.scss"

const Search = () => {
    const [region, setRegion] = useState("arab");
  const [showAll, setshowAll] = useState(false);
  const [showMain, setshowMain] = useState(false);

  const [text, setText] = useState("");

  const inputRef = useRef();

  const filteredData = SearchData.map((item) => {
    if (!text) return true; // Return true if text is empty to show all items

    // Filter the submenu based on the text
    const filteredSubmenu = Object.entries(item.submenu).filter(
      ([category, subItems]) =>
        category.toLowerCase().includes(text.toLowerCase()) ||
        subItems.some((subItem) =>
          subItem.name.toLowerCase().includes(text.toLowerCase())
        )
    );

    // If there are no matches in the submenu, return null to exclude the item
    if (filteredSubmenu.length === 0) return null;

    // Otherwise, return the item with the filtered submenu
    return {
      ...item,
      submenu: Object.fromEntries(filteredSubmenu),
    };
  }).filter(Boolean); // Remove any null values from the array

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (inputRef.current && !inputRef.current.contains(event.target)) {
        setshowAll(false);
        setshowMain(false)
      }
    };

    // Add event listener when the component mounts
    document.addEventListener('mousedown', handleClickOutside);

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);
  return (
    <div className="w-75 my-4 py-2 hero_search d-flex" ref={inputRef}>
            <span>in</span>

            <input
              type="text"
              placeholder="Where"
              className="hero_input"
              value={text}
              onChange={(e) => {setText(e.target.value)
                setshowAll(false)
                setshowMain(true)
              }}
            />
            <i
              class="fa fa-chevron-down"
              aria-hidden="true"
              onClick={() => setshowAll(!showAll)}
            ></i>
            {text && !showAll && showMain&& (
              <div className="all_option">
                <div className="all_countries" onClick={() => setshowAll(true)}>
                  {" "}
                  <i class="fa fa-arrow-left mr-1" aria-hidden="true"></i> View
                  All Countries
                </div>
                <>
                  {filteredData.map((item) => {
                    return Object.entries(item.submenu).map(
                      ([category, subItems]) => (
                        <div
                          key={category}
                          className="option-category py-2 mb-2 w-100"
                        >
                          <div key={category} className="option_link ">
                            <Link to={category.toLowerCase()} className="  category_link w-100 py-2 px-2">
                              {category}
                            </Link>
                          </div>
                          {subItems.map((subItem) => (
                            <div
                              key={subItem.id}
                              className="py-2 px-2 option_link "
                            >
                              <Link
                                className="subcategory_link "
                                to={subItem.name.toLowerCase()}
                              >
                                {subItem.name}
                              </Link>
                            </div>
                          ))}
                        </div>
                      )
                    );
                  })}
                </>
              </div>
            )}
            <div className="options">
              {showAll && (
                <>
                  <ul class="nav nav-tabs w-100 px-4 pt-3">
                    <li class="nav-item mx-2">
                      <div
                        class={`nav-link px-0 ${
                          region === "arab" ? "active" : ""
                        }`}
                        onClick={() => setRegion("arab")}
                      >
                        Arab
                      </div>
                    </li>
                    <li class="nav-item mx-2">
                      <div
                        class={`nav-link px-0 ${
                          region === "International" ? "active" : ""
                        }`}
                        onClick={() => setRegion("International")}
                      >
                        International
                      </div>
                    </li>
                  </ul>
                  <div className="option_choise">
                    {Object.entries(
                      SearchData[region === "arab" ? 0 : 1].submenu
                    ).map(([category, subItems]) => (
                      <div
                        key={category}
                        className="option-category py-2 mb-2 w-100"
                      >
                        <div key={category} className="option_link py-2 my-2">
                          <Link to={category.toLowerCase()} className="  category_link w-100 px-2">
                            {category}
                          </Link>
                        </div>
                        {subItems.map((subItem) => (
                          <div
                            key={subItem.id}
                            className="py-2 px-2 option_link "
                          >
                            <Link
                              className="subcategory_link "
                              to={subItem.name.toLowerCase()}
                            >
                              {subItem.name}
                            </Link>
                          </div>
                        ))}
                      </div>
                    ))}
                  </div>
                </>
              )}
            </div>
          </div>
  )
}

export default Search