import React from "react";
import DestinationCard from "../../molecules/cards/DestinationCard";

const Tips = () => {
  const marryData = [
    {
      id: 1,
      title: "Wedding Dresses",
      img: "https://www.arabiaweddings.com/sites/default/files/styles/400x400/public/images/thumbnails/2017/08/117271_wedding_dresses_2017.jpeg",
    },
    {
      id: 1,
      title: "Hairstyles",
      img: "https://imagesawe.s3.amazonaws.com/styles/400x400/s3/images/thumbnails/2019/06/gorgeous-wedding-hairstyle-with-flower.jpg",
    },
    {
      id: 1,
      title: "Makeup",
      img: "https://imagesawe.s3.amazonaws.com/styles/400x400/s3/images/thumbnails/2019/06/fady_kataya_1.png",
    },
    {
      id: 1,
      title: "Designer Dresses",
      img: "https://imagesawe.s3.amazonaws.com/styles/400x400/s3/images/thumbnails/2019/06/41157_sendo_41208_sandie.jpg",
    },
    {
      id: 1,
      title: "Invitations",
      img: "https://imagesawe.s3.amazonaws.com/styles/400x400/s3/images/thumbnails/2019/06/diy-gold-and-cream-laser-cut-wedding-invitations-card-swws007.jpg",
    },
    {
      id: 1,
      title: "Destination Weddings",
      img: "https://imagesawe.s3.amazonaws.com/styles/400x400/s3/images/thumbnails/2019/06/ideal-honeymoon-online.jpg",
    },
    {
      id: 1,
      title: "Wedding Rings",
      img: "https://imagesawe.s3.amazonaws.com/styles/400x400/s3/images/thumbnails/2019/06/rings.jpg",
    },

    {
        id: 1,
        title: "Engagement Dresses",
        img: "https://www.arabiaweddings.com/sites/default/files/styles/400x400/public/images/thumbnails/2017/08/engegment_dress.jpg",
      },
      {
        id: 1,
        title: "Favors & Gifts",
        img: "https://www.arabiaweddings.com/sites/default/files/styles/400x400/public/articles/2020/04/wedding_favors_6.jpg?itok=hrGYfxB0",
      },
      {
        id: 1,
        title: "Bridal Bouquets",
        img: "https://imagesawe.s3.amazonaws.com/styles/400x400/s3/images/thumbnails/2019/10/bridal_bouquet.png?itok=KAhN-j5y",
      },
      {
        id: 1,
        title: "Koshas",
        img: "https://www.arabiaweddings.com/sites/default/files/styles/400x400/public/images/thumbnails/2017/08/kosha_-_th.jpg",
      },
      {
        id: 1,
        title: "Wedding Cakes",
        img: "https://www.arabiaweddings.com/sites/default/files/styles/400x400/public/images/thumbnails/2017/08/wedding_cake_-_thumb_1.jpg?itok=KMpIXKfn",
      },
  ];
  return (
    <div className="px-lg-5 px-3 my-4">
      <h2 class="my-4 text-md-left text-center text-xl bold ">
        Ideas and Tips
      </h2>
      <p class="mb-3 text-md-left text-center text-xl bold ">
        Get inspired with the latest trends and advice from around the world
      </p>
      <div className="row">
        {marryData.map((card, index) => (
          <div key={index} className="col-md-2 col-6 my-2">
            <DestinationCard img={card.img} title={card.title} />
          </div>
        ))}
      </div>
    </div>
  );
};

export default Tips;
