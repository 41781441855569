import React, { useState } from "react";
import { HashRouter as Router, Routes, Route } from "react-router-dom";

// scss
import "./App.scss";

// components
import Header from "./organisms/header/Header";
import Home from "./pages/home/Home";
import SideBar from "./molecules/navBar/sideBar/SideBar";
import Footer from "./organisms/footer/Footer";
import Whatsapp from "./atomic/whatsapp/Whatsapp";
import ScrollToTop from "./atomic/scrollToTop/ScrollToTop";
import ScrollToTopBtn from "./atomic/scrollToTopBtn/ScrollToTopBtn";
import Login from "./pages/login";
import Signup from "./pages/signup";
import Vendor from "./pages/vendor";
import WedingPakages from "./pages/wedingPakages";
import Inspiration from "./pages/inspiration";
import PakagesDetail from "./pages/pakagesDetail"; 
import VendorDetail from "./pages/vendorDetail";

const App = () => {
  return (
    <>
      <Router>
        <Routes>
          <Route
            path="/"
            element={
              <Layout>
                <Home />
              </Layout>
            }
          />
          <Route
            path="/:name"
            element={
              <Layout>
                <Vendor />
              </Layout>
            }
          />
          <Route
            path="weddingpakages/:name"
            element={
              <Layout>
                <WedingPakages />
              </Layout>
            }
          />
           <Route
            path="pakagesDetail/:name"
            element={
              <Layout>
                <PakagesDetail />
              </Layout>
            }
          />
           <Route
            path="vendorDetail/:name"
            element={
              <Layout>
                <VendorDetail />
              </Layout>
            }
          />
           <Route
            path="inspiration/:name"
            element={
              <Layout>
                <Inspiration />
              </Layout>
            }
          />
          <Route path="/signin" element={<Login />} />
          <Route path="/signup" element={<Signup />} />
        </Routes>
      </Router>
    </>
  );
};
const Layout = ({ children }) => {
  const [mobileMenu, setMobileMenu] = useState(true);
  return (
    <>
      <Whatsapp />
      <ScrollToTop />
      <ScrollToTopBtn />
      <SideBar mobileMenu={mobileMenu} setMobileMenu={setMobileMenu} />
      <Header mobileMenu={mobileMenu} setMobileMenu={setMobileMenu} />
      {children}
      <Footer />
    </>
  );
};

export default App;
