import React from 'react'
import Hero from '../../organisms/hero/Hero'
import Married from '../../organisms/married/Married'
import Journey from '../../organisms/journey/Journey'
import Wedding from '../../organisms/wedding/Wedding'
import Destination from '../../organisms/destination/Destination'
import Tips from '../../organisms/tips/Tips'
import Choise from '../../organisms/choice/Choise'
import Listen from '../../organisms/listen/Listen'

const Home = () => {
  return (
    <div>
      <Hero />
      <Married />
      <Journey />
      <Wedding />
      <Destination />
      <Choise />
      <Tips />
      {/* <Listen /> */}
    
    </div>
  )
}

export default Home