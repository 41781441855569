import React from "react";
import ReactPaginate from "react-paginate";
import"./pagination.scss";

const Pagination = ({ data, itemsPerPage, handlePageChange }) => {
  return (
    <ReactPaginate
      pageCount={Math.ceil(data.length / itemsPerPage)}
      pageRangeDisplayed={5}
      marginPagesDisplayed={2}
      previousLabel={"Previous"}
      nextLabel={"Next"}
      breakLabel={"..."}
      onPageChange={handlePageChange}
      containerClassName={"pagination"}
      activeClassName={"active"}
    />
  );
};

export default Pagination;
